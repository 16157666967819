import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { withTranslation } from 'react-i18next';

class CareerSection2 extends Component {
  render() {
    let data = sectiondata.mission_career;
    let publicUrl = process.env.PUBLIC_URL
    const { t, i18n } = this.props;
    const currentLang = i18n.language;

    const missionVissionAreaStyle = {
      paddingBottom: '90px',
      paddingTop: "90px",
      position: 'relative',
      zIndex: '1'
    };

    const bgBox = {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      filter: 'blur(5px) brightness(0.5)',
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/jianzhu2.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backdropFilter: 'blur(3px)',
      zIndex: '-1',
    }

    const missionTitleStyle = {
      textAlign: 'center',
      marginBottom: '40px',
      color: ""
    };

    const gridContainerStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Exactly three columns
      gap: '20px'
    };

    const singleIntroStyle = {
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fff',
      textAlign: 'left'
    };

    const textStyle = {
      fontSize: '2rem',
      color: '#edc987',
      marginBottom: '10px'
    };

    const titleStyle = {
      fontSize: '1.25rem',
      marginBottom: '10px'
    };

    const paragraphStyle = {
      marginBottom: '0'
    };

    return (
        <div style={missionVissionAreaStyle} >
          <div className="container" >
            {currentLang === 'zh' && <div style={{ ...missionTitleStyle, marginBottom: "50px" }}>
              <h1 style={{ fontFamily: "Georgia, serif",color:"#fff" }}>欢迎志同道合的你加入我们!</h1>
            </div>}
            <div style={{...missionTitleStyle}}>
              <h1 style={{color:"var(--main-color-one)"}}>Why Join Helen Realty?</h1>
            </div>
            <div style={gridContainerStyle} >
              {data.items.map((item, i) => (
                <div key={i} style={singleIntroStyle} className='animation-hover'>
                  <div style={textStyle} className='title-hover'>{item.number}</div>
                  <div className="details">
                    <h4 style={titleStyle} className='p-hover'>
                      {/* <a href="#">{item.title}</a> */}
                      {t(item.title)}
                    </h4>
                    <p className='p-hover' style={paragraphStyle}>{t(item.content)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
         <div style={bgBox}></div>
        </div>
    );
  }
}

export default withTranslation()(CareerSection2);