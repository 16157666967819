import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

class Featured extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.featuredproperties
        let Customclass = this.props.Customclass ? this.props.Customclass : 'pd-top-60'

        const data1 = {
          items: [
            { image: 'assets/img/icons/31.png', url: '/url1', title: 'Title 1', price: '$100', content: 'Content 1' },
            { image: 'image2.jpg', url: '/url2', title: 'Title 2', price: '$200', content: 'Content 2' },
            { image: 'image3.jpg', url: '/url3', title: 'Title 3', price: '$300', content: 'Content 3' },
            { image: 'image4.jpg', url: '/url4', title: 'Title 4', price: '$400', content: 'Content 4' }
          ]
        };

        const gridContainerStyle = {
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 1fr)',
          gridTemplateRows: 'repeat(4, 1fr)',
          gap: '10px',
          padding: '10px'
        };
      
        const gridItemStyle = {
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative'
        };
      
        const itemStyles = [
          { gridColumn: 'span 4', gridRow: 'span 2' },
          { gridColumn: 'span 2', gridRow: 'span 2' },
          { gridColumn: 'span 3', gridRow: 'span 2' },
          { gridColumn: 'span 3', gridRow: 'span 2' },
          { gridColumn: 'span 4', gridRow: 'span 2' }
        ];
      
        const slfOverlayStyle = {
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          background: 'rgba(0, 0, 0, 0.5)'
        };
      
        const thumbStyle = {
          width: '100%',
          height: 'auto'
        };
      
        const detailsStyle = {
          padding: '10px',
          background: 'white'
        };
      
        const titleLinkStyle = {
          textDecoration: 'none',
          color: 'black'
        };
      
        const priceStyle = {
          color: 'green',
          fontSize: '1.2em'
        };
      
        const spanStyle = {
          display: 'block',
          marginTop: '10px'
        };


    return <div className={"featured-area  "+Customclass}>
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">{ data.title }</h2>
            </div>
            <div className="row justify-content-center">
            <div style={gridContainerStyle}>
      {data.items.map((item, index) => (
        <div key={index} style={{ ...gridItemStyle, ...itemStyles[index] }}>
          <div className="single-leading-feature">
            <div style={slfOverlayStyle} />
            <div className="thumb">
              <img src={publicUrl + item.image} alt={imagealt} style={thumbStyle} />
            </div>
            <div className="details" style={detailsStyle}>
              <h4 className="title readeal-top">
                <Link to={item.url} style={titleLinkStyle}>{item.title}</Link>
              </h4>
              <h5 className="price" style={priceStyle}>{item.price}</h5>
              <span style={spanStyle}>{item.content}</span>
            </div>
          </div>
        </div>
      ))}
    </div>

           
              {/* { data.items.map( ( item, i )=>
                  <div key={ i } className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-feature">
                      <div className="thumb">
                        <img src={ publicUrl+item.image } alt={ imagealt } />
                      </div>
                      <div className="details">
                        <a href="#" className="feature-logo">
                          <img src={publicUrl+item.icon} alt={ imagealt } />
                        </a>
                        <p className="author"><i className="fa fa-user" /> { item.authorname }</p>
                        <h6 className="title readeal-top"><Link to={ item.url }>{ item.title }</Link></h6>
                        <h6 className="price">{ item.newerprice }</h6><del>{ item.olderprice }</del>
                        
                        <ul className="info-list">

                        { item.features.map( ( features, i )=>
                          <li key={ i } ><i className={ features.icon } /> { features.title }</li>
                         ) }  
                          <li><img src={publicUrl+"/assets/img/icons/7.png"} alt="img" /> { item.area }</li>
                        </ul>


                        <ul className="contact-list">
                          <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                          <li><a className="message" href="#"><img src={ publicUrl+"/assets/img/icons/8.png" } alt="img" /></a></li>
                          <li className="readeal-top"><Link className="btn btn-yellow" to={item.url}>View Details</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
               ) } */}
              
            </div>
          </div>
        </div>



        }
}

export default Featured