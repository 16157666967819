import React from 'react';
import Navbar from './global-components/navbar';
import Bannerv3 from './section-components/banner-v3';
import Footer from './global-components/footer';
import BuySellTable from './section-components/buy-sell-table';

const GetAppraisal = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Get Appraisal" /> */}
        <Bannerv3 />
        <BuySellTable />
        <Footer />
    </div>
}

export default GetAppraisal