import React, { Component } from 'react';
import axios from 'axios'; // Import axios
// re-direct
import { useHistory } from 'react-router-dom';

class AdminData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
    }

    handleLogin = async (e) => {
        e.preventDefault();
        console.log(this.state.username)
        console.log(this.state.password)
        try {
            const response = await axios.post('/login', {
                username: this.state.username,
                password: this.state.password
            });
            console.log(response.data);
            // Redirect to /helenadmin on successful login
            if (response.status === 200) {
                //
                alert('Login successed! Please visit to helendata');
                this.props.history.push('/helendata');
            } else {
                alert('Login failed!');
            }
        } catch (error) {
            console.error('Login failed: ', error);
            alert('Login failed!');
        }
    };

    render() {
        const styles = {
            form: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '50px',
                padding: '40px',
                background: '#ffffff', // Changed to white background for a cleaner look
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // Increased shadow for better depth
                borderRadius: '12px', // More rounded corners
                width: '400px',
                margin: 'auto',
                gap: '20px' // Added gap between form elements for better spacing
            },
            input: {
                width: '100%',
                height: '50px',
                marginBottom: '20px',
                border: '1px solid #ddd',
                borderRadius: '8px', // More rounded corners for inputs
                padding: '0 15px',
                fontSize: '16px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Slight shadow for inputs
                transition: 'border-color 0.3s', // Smooth transition for border color
            },
            inputFocus: {
                borderColor: '#0056b3' // Border color change on focus
            },
            button: {
                width: '100%',
                height: '50px',
                background: '#edc987', // Changed button color
                color: 'white', // Button text color
                border: 'none',
                borderRadius: '8px', // More rounded corners for button
                cursor: 'pointer',
                fontSize: '18px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow for button
                transition: 'background 0.3s, transform 0.2s', // Smooth transition for background and transform
            },
            buttonHover: {
                background: '#d9a771', // Darker button color on hover
                transform: 'scale(1.05)', // Slightly enlarge button on hover
            }
        };

        return (
            <form style={styles.form} onSubmit={this.handleLogin}>
                <input
                    type="text"
                    value={this.state.username}
                    onChange={e => this.setState({ username: e.target.value })}
                    placeholder="Username"
                    style={styles.input}
                    onFocus={e => e.target.style.borderColor = styles.inputFocus.borderColor}
                    onBlur={e => e.target.style.borderColor = '#ddd'}
                    required
                />
                <input
                    type="password"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                    placeholder="Password"
                    style={styles.input}
                    onFocus={e => e.target.style.borderColor = styles.inputFocus.borderColor}
                    onBlur={e => e.target.style.borderColor = '#ddd'}
                    required
                />
                <button
                    type="submit"
                    style={styles.button}
                    onMouseEnter={e => {
                        e.target.style.background = styles.buttonHover.background;
                        e.target.style.transform = styles.buttonHover.transform;
                    }}
                    onMouseLeave={e => {
                        e.target.style.background = styles.button.background;
                        e.target.style.transform = 'scale(1)';
                    }}
                >
                    Log In
                </button>
            </form>
        );
    }

}
function AdminDataWithHistory() {
    const history = useHistory();
    return <AdminData history={history} />;
}

export default AdminDataWithHistory;