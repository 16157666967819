import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 导入翻译文件
import translationEN from './pages/en/translation.json';
import translationZH from './pages/cn/translation.json';

// 初始化 i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      zh: {
        translation: translationZH
      }
    },
    // 使用浏览器的语言设置，如果是中文则默认为中文，否则为英文
    lng: navigator.language.startsWith('zh') ? "zh" : "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
