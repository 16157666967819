import { useEffect, useState } from "react"
import 'animate.css';
import { WOW } from 'wowjs';
const Select = () => {
    useEffect(() => {
        new WOW().init();
    }, []);
    const data = [
        {
            label: "SELL",
            Placeholder: "Start typing to find your address",
            title: "What's your property worth?",
            btnText: "Find Out"
        }, {
            label: "BUY",
            Placeholder: "Search by suburb...",
            title: "Let's find your next home",
            btnText: "Search"
        }, {
            label: "MANAGE",
            Placeholder: "Start typing to find your address",
            title: "Discover your investment’s potential",
            btnText: "Find Out"
        }, {
            label: "RENT",
            Placeholder: "Search by suburb...",
            title: "Looking for your next move?",
            btnText: "Search"
        }
    ]
    const [selected, setSelected] = useState(data[0])
    // const selectData = useEffect(()=>{
    //     return data.filter(item => item.label === selected)[0].title
    // },[selected])
    const bgBox = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        filter: 'blur(2px) brightness(0.5)',
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/jianzhu3.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backdropFilter: 'blur(3px)',
        zIndex: '-1',
      }
    return (
        <>
            <div className="select-wrap  wow  animate__animated animate__fadeIn" style={{position:'relative',zIndex:"1",padding:"40px 0",marginTop:'10px'}}>
                <div className="container">
                    <div className="select-title">
                        <h2 className="text-center" style={{marginBottom:'30px',color:"#fff"}}>{selected.title}</h2>
                    </div>
                    <div className="search-form-tabs">
                        {data.map(item => {
                            return <a key={item.label} className={item.label === selected.label ? 'search-form-tabs-active' : ''} onClick={() => setSelected(item)}>{item.label}</a>
                        })}
                    </div>
                    <div className="input-wrap">
                        <input placeholder={selected.Placeholder}></input>
                        <button className="input-wrap-filters">Filters</button>
                        <button className="input-wrap-find">Find Out</button>
                    </div>
                </div>
            <div style={bgBox}></div>
            </div>

        </>
    )
}

export default Select