import React from 'react';
import Navbar from './global-components/navbar';
import Mission from './section-components/mission';
import AboutUs from './section-components/about-us';
import ServiceTwo from './section-components/service-two';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import Team from './section-components/team';
// import Client from './section-components/client';
import AboutBannerSection from './section-components/about_banner_section';



const About = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const type = new URLSearchParams(location.search).get('type');
    const currentLanguage = i18n.language;
     console.log(type,'1111');

    console.log(i18n.language,'2222');
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="About" /> */}
        {/* Yuchen加 */}
        <AboutBannerSection />
        {/* Yuchen改 */}
        
        {/* Yuchen改 */}
        {(currentLanguage !=='zh' || type==='brief') ? <Mission /> : <div style={{height:"40px"}}></div>}
        
        {/* Yuchen改 */}
        {(currentLanguage !=='zh' || type==='brief') && <AboutUs />}
        {!(currentLanguage =='zh' && type=='brief') && <ServiceTwo />}
        {/* <Team /> */}
        {/* <Client /> */}
        <Footer />
    </div>
}

export default About
