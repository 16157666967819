import React, { Component } from 'react';

class AboutSection1 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        const sectionStyle = {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '50px 20px',
            backgroundImage: 'url(' + publicUrl + 'assets/img/banner/aboutbanner.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#fff',
            height: '100vh',
            textAlign: 'center',
        };

        const overlayStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
            zIndex: 1,
        };

        const contentStyle = {
            position: 'relative',
            zIndex: 2,
        };

        const headingPrimaryStyle = {
            fontSize: '4rem',
            fontFamily: 'Georgia, serif',
            color: '#fff',
            marginBottom: '10px',
        };

        const headingSecondaryStyle = {
            fontSize: '4rem',
            fontFamily: 'Georgia, serif',
            color: '#edc987',
            marginBottom: '20px',
        };

        const paragraphStyle = {
            fontSize: '1.5rem',
            color: '#fff',
            marginBottom: '30px',
            maxWidth: '800px',
        };

        const buttonContainerStyle = {
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
        };

        const buttonStyle = {
            fontSize: '1rem',
            color: '#fff',
            border: '1px solid #edc987',
            backgroundColor: '#edc987',
            padding: '10px 20px',
            cursor: 'pointer',
            borderRadius: '5px',
            textDecoration: 'none',
            display: 'inline-block',
        };

        return (
            <div style={sectionStyle}>
                <div style={overlayStyle}></div>
                <div style={contentStyle}>
                    <div>
                        <span style={headingPrimaryStyle}>Helen</span>
                        <span style={headingSecondaryStyle}>Projects</span>
                    </div>
                    <p style={paragraphStyle}>
                        Helen Realty is a dynamic & innovative property firm based in South Australia, selling more than 1000 homes a year and managing close to 3000 investment properties.
                    </p>
                    <div style={buttonContainerStyle}>
                        <a href="#" style={{ ...buttonStyle, backgroundColor: 'transparent' }}>Current Projects</a>
                        {/* <a href="#" style={buttonStyle}>Find a sales agent</a> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutSection1;