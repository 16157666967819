import React from 'react';

const OwnerInfo = ({ imgSrc, imgAlt, name, title, logoAlt }) => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  const logoSrc = `${publicUrl}assets/img/helenlogo.png`;  

  return (
    <div className="owner-info text-center" style={{ position: 'relative', display: 'inline-block' }}>
      <div className="thumb" style={{ position: 'relative',border:'none' }}>
        <img src={imgSrc} alt={imgAlt} style={{ width: '100%' }} />
        <img 
          src={logoSrc}
          alt={logoAlt} 
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            width: '50px', // Adjust the size as needed
            height: '50px'  // Adjust the size as needed
          }} 
        />
      </div>
      <div className="details">
        <h4>{name}</h4>
        <p>{title}</p>
        {/* <p className="reviews"><i className="fa fa-star" /><span>4.8</span> 70 Review</p> */}
      </div>
    </div>
  );
};

export default OwnerInfo;

