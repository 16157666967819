import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertySection from './section-components/recent-properties-for-sold';
import TopAuthor from './section-components/top-author';
import Footer from './global-components/footer';
import Ads from './section-components/ads';

const AvailableProperty = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Property For Sale" subheader="On the market properties" />
        <PropertySection />
        <Ads />
        <br />
        <br />
        {/* <TopAuthor /> */}
        <Footer />
    </div>
}

export default AvailableProperty

