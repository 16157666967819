import React, { Component } from 'react';

class AboutSection extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const sectionStyle = {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '50px 20px',
      backgroundImage: 'url(' + publicUrl + 'assets/img/banner/aboutbanner.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: '#fff',
      height: '80vh',
      textAlign: 'center',
    };

    const overlayStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
      zIndex: 1,
    };

    const contentStyle = {
      position: 'relative',
      zIndex: 2,
    };

    const headingPrimaryStyle = {
      fontSize: '4rem',
      fontFamily: 'Georgia, serif',
      color: '#fff',
      marginBottom: '10px',
    };

    const headingSecondaryStyle = {
      fontSize: '4rem',
      fontFamily: 'Georgia, serif',
      color: ' #edc987',
      marginBottom: '20px',
    };

    const paragraphStyle = {
      fontSize: '1.5rem',
      color: '#fff',
      marginBottom: '30px',
      maxWidth: '800px',
    };

    const buttonContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
    };

    const buttonStyle = {
      fontSize: '1rem',
      color: '#fff',
      border: '1px solid  #edc987',
      backgroundColor: '#edc987',
      padding: '10px 20px',
      cursor: 'pointer',
      borderRadius: '5px',
      textDecoration: 'none',
      display: 'inline-block',
    };

    return (
      <div style={sectionStyle}>
        <div style={overlayStyle}></div>
        <div style={contentStyle}>
          <div>
            <span style={headingPrimaryStyle}>About</span>
            <span style={headingSecondaryStyle}> Helen Realty</span>
          </div>
          <p style={paragraphStyle}>
            Helen Realty is a dynamic & innovative property firm based in South Australia, selling more than 1000 homes a year and managing close to 3000 investment properties.
          </p>
          <div style={buttonContainerStyle}>
            <a href="#" style={{ ...buttonStyle, backgroundColor: 'transparent' }}>Get an instant appraisal</a>
            <a href="#" style={buttonStyle}>Find a sales agent</a>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSection;



// import React, { Component } from 'react';


// class AboutBannerSection extends Component {
//     render(){
//         let publicUrl = process.env.PUBLIC_URL+'/';
//         const banner_style = {
//             backgroundImage: 'url('+publicUrl+'/assets/img/banner/1.jpg)',
//             backgroundSize: 'cover', // Ensures the image covers the container
//             backgroundPosition: 'center', // Centers the background image
//             backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat

//             display: 'flex',
//             height: '976px',
//             justifyContent: 'center',
//             alignItems: 'center',
//         };
//         const content_style = {
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             textAlign: "center",
//             width: "50%",
//         }
//         const button_style = {
//                 padding: "10px",
//                 margin: "10px",
//                 borderRadius: "4px",
//                 fontSize: "20px",
//         }
//         return(
//             <>
//                 <div style={banner_style}>
//                     <div className='content' style={content_style}>
//                         <div className='content_header'>
//                             <h1>About <span style={{color: "orange"}}>HELEN REALTY</span></h1>
//                         </div>
//                         <div className='content_body'>
//                             <h4>HELEN REALTY is a dynamic & innovative property firm based in South Australia, selling more than 1000 homes a year and managing close to 3000 investment properties.</h4>
//                         </div>
//                         <div className='content_buttons'>
//                             <button style={{backgroundColor: "orange", color:"white", ...button_style}}>Get an instant appraisal</button>
//                             <button style={{backgroundColor: "transparent", color:"orange", ...button_style}}>Find a sales agent</button>
//                         </div>
//                     </div>
//                 </div>            
//             </>
//         )
//     }
// }

// export default AboutBannerSection