import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import 'animate.css';
import { WOW } from 'wowjs';
import { withTranslation } from 'react-i18next'; 
class Testimonials extends Component {
    componentDidMount(){
      new WOW().init();
    }
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = sectiondata.recentproperties
        const { t } = this.props;
        return (
            <div className="properties-area pd-top-70 pd-bottom-70" style={{position:"relative"}}>
                <div className="container">
                    <div className="section-title">
                        <h3 className="title">{data.title}</h3>
                        <Link className="btn-view-all" to={data.btnurl}></Link>
                    </div>
                    <div className="row">
                        {data.items.slice(0, 3).map((item, i) =>
                            <div key={i} className="col-lg-4 col-sm-8 wow  animate__animated animate__fadeIn">
                                <div className="single-feature" style={{height:'100%'}}>
                                    <div className="thumb">
                                        <img src={publicUrl + item.image} alt="img" style={{ height: '280px', objectFit: 'cover' }} />
                                    </div>
                                    <div className="details" style={{height:'calc(100% - 310px)',borderRadius:"0 0 10px 10px"}}>

                                        <p className="author"><i className="fa fa-user" /> {item.authorname}</p>
                                        <h6 className="title readeal-top"><Link to={item.url}>{item.title}</Link></h6>
                                        <h6 className="price">{item.newerprice}</h6>
                                        <h6 className="price">{item.Acomment}</h6>
                                        <ul className="info-list">
                                            <h6 className="price">{t(item.comment)}</h6>
                                        </ul>

                                        <ul className="info-list">
                                            <h6 className="price">{t(item.review)}</h6>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Testimonials);