import React from 'react';
import Navbar from './global-components/navbar';
import Footer from './global-components/footer';
import ReportPreviewDetail from "./section-components/report-preview";
const ReportPreview = () => {
    return (
        <>
        <Navbar></Navbar>
        <ReportPreviewDetail/>
        <Footer></Footer>
        </>
    )
}

export default ReportPreview