import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Rentprocess from './section-components/rent-processes';
import Claim from './section-components/claim';


const Disclaimer = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Disclaimer" />
        <Claim />
        {/* <Professional1 /> */}
        {/* <SellHome /> */}
        {/* <Mission /> */}
        {/* <Process /> */}
        {/* <BuyOrSell /> */}
        <Footer />
    </div>
}

export default Disclaimer