import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation } from 'react-i18next';

class AboutUs extends Component {
    render() {
        const { t } = this.props;
        let publicUrl = process.env.PUBLIC_URL + '/';
        let data = sectiondata.aboutus;
        let media = [
            { type: 'image', src: publicUrl + 'assets/img/companyphoto.png', alt: 'image' },
            { type: 'image', src: publicUrl + 'assets/img/helen_room.jpg', alt: 'another image' },
            { type: 'video', src: publicUrl + 'assets/img/sampleVideo.mp4', alt: 'video' }
        ];

        const styles = {
            aboutArea: {
                paddingBottom: '90px'
            },
            carouselItem: {
                height: '400px',
                // display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            media: {
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            },
            carouselIndicators: {
                bottom: '-30px'
            },
            carouselIndicator: {
                backgroundColor: '#ffc107',
                borderRadius: '50%'
            },
            carouselIndicatorActive: {
                backgroundColor: '#343a40'
            }
        };

        return (
            <div className="about-area" style={styles.aboutArea}>
                <div className="container" style={{maxWidth:"1300px"}}>
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <Carousel
                                indicatorsProps={{
                                    style: styles.carouselIndicators
                                }}
                                indicatorProps={{
                                    style: styles.carouselIndicator
                                }}
                                activeIndicatorProps={{
                                    style: styles.carouselIndicatorActive
                                }}
                            >
                                {media.map((item, index) => (
                                    <Carousel.Item key={index} style={styles.carouselItem}>
                                        {item.type === 'image' ? (
                                            <img
                                                className="d-block w-100 shadow-img"
                                                src={item.src}
                                                alt={item.alt}
                                                style={styles.media}
                                            />
                                        ) : (
                                            <video className="d-block w-100 shadow-img" controls style={styles.media}>
                                                <source src={item.src} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-title pd-left mb-0">
                                <h5 className="sub-title">{data.subtitle}</h5>
                                <h2 className="title">{data.title}</h2>
                                <p>{t(data.content)}</p>
                                <a className="btn btn-yellow" href={data.url}>{data.btn_text}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AboutUs);

// import React, { Component } from 'react';
// import sectiondata from '../../data/sections.json';
// import parse from 'html-react-parser';

// class AboutUs extends Component {


//     render() {

//         let publicUrl = process.env.PUBLIC_URL+'/'
//         let image1 = publicUrl+'assets/img/companyphoto.png';
//         let imagealt = 'image'
//         let data = sectiondata.aboutus


//     return <div className="about-area pd-bottom-90">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6 mb-4 mb-lg-0">
//               <div className="shape-image-list-wrap">
//                 <div className="shape-image-list left-top">
//                   <img className="shadow-img" src={image1} alt={ imagealt } />
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 align-self-center">
//               <div className="section-title pd-left mb-0">
//                 <h5 className="sub-title">{ data.subtitle }</h5>
//                 <h2 className="title">{ data.title }</h2>
//                 <p>{ data.content }</p>
//                 <a className="btn btn-yellow" href={ data.url } >{ data.btn_text }</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//         }
// }

// export default AboutUs