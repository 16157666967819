// PropertyDetails.js
import React, { useState, useEffect } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import RecomandProperties from './section-components/recomand-properties';
import Footer from './global-components/footer';
import { getSavedAddressDetails } from './section-components/addressService'; // 导入 getSavedAddressDetails
import sectiondata from '../data/sections.json'; // 导入 sectiondata

const PropertyDetails = () => {
    const [headerTitle, setHeaderTitle] = useState('Property Details');

    useEffect(() => {
        const savedAddressDetails = getSavedAddressDetails();
        const currentAddressDetails = savedAddressDetails.length > 0 ? savedAddressDetails[0] : sectiondata.searchlist.items[0];
        setHeaderTitle(currentAddressDetails.title);
    }, []);

    const getHeaderTitle = (val) =>{
        setHeaderTitle(val);
    };

    const [showNav,setShowNav] = useState(true)

    return (
        <div>
            {showNav && <Navbar/>}
            <PageHeader headertitle={headerTitle} />
            {/* <p>Property Detail page is still under development</p> */}
            <PropertyDetailsSection getHeaderTitle = {getHeaderTitle} setShowNav={setShowNav} />
            {/* <RecomandProperties /> */}
            <Footer />
        </div>
    );
}

export default PropertyDetails;
