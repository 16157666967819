import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import { getSavedAddressDetails } from './addressService'; // 导入 getSavedAddressDetails
import { getListing } from '../../data/api';
import { withRouter } from 'react-router-dom';
class SearchList extends Component {
  constructor(props) {
    const savedAddressDetails = getSavedAddressDetails();
    super(props);
    this.state = {
      // addressDetails: getSavedAddressDetails().length > 0 ? getSavedAddressDetails() : sectiondata.searchlist.items // 获取保存的地址信息
      addressDetails: (savedAddressDetails && savedAddressDetails.length > 0) ? savedAddressDetails : sectiondata.searchlist.items,
      RentalItems: [],
    };
  }
  componentDidMount() {
    getListing().then((res) => {
      console.log('res', res);
      // filter all the latest item in the array from @attributes->date
      // sort the array by date, the date format is '2024-08-23-15:16:04'
      res.sort((a, b) => {

        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringA = a['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectA = new Date(formattedTimeStringA);

        // 获取时间戳（毫秒）
        const timestampA = dateObjectA.getTime();


        // 将时间字符串中的 "-" 替换为 " "，以便构造新的 Date 对象
        const formattedTimeStringB = b['@attributes'].date.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z";

        // 创建 Date 对象
        const dateObjectB = new Date(formattedTimeStringB);

        // 获取时间戳（毫秒）
        const timestampB = dateObjectB.getTime();
        return timestampB - timestampA;

        // return new Date(b['@attributes'].date) - new Date(a['@attributes'].date);
      });


      // get the first 3 items
      let latestItems = res.slice(0, 7);

      console.log('latestItems', latestItems);


      // add all the items in each object's residential array to a new array
      let RentalItems = [];
      res.forEach((item) => {
        if (item.rental) {
          RentalItems = RentalItems.concat(item.rental);
        }

      });
      console.log('RentalItems', RentalItems);

      // filter all the unique residential items by uniqueID in the array
      let uniqueRentalItems = RentalItems.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.uniqueID === item.uniqueID
        ))
      );
      console.log('uniqueRentalItems', uniqueRentalItems);
      uniqueRentalItems.forEach(item=>item.url = `/property-details-rent?uniqueID=${item.uniqueID}&type=rent`)



      this.setState({ RentalItems: uniqueRentalItems });

    });


    // // 假设数据通过API获取
    // fetch('http://localhost:3000/api/read-ftp-file')
    //   .then(response => response.json())
    //   .then(data => {
    //     this.setState({ propertyList: data });  // 将获取到的数据存入state
    //   })
    //   .catch(error => {
    //     console.error('Error fetching the data:', error);
    //   });
  }
  handleImageClick = (url) => {
    this.props.history.push(url);
  };
  iStyle = {
    width:'25px',
    fontSize: '18px',
    marginRight:"5px"
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';
    let { addressDetails } = this.state;
    let { propertyList } = this.state;
    // console.log(propertyList);

    return (
      <div className="search-page-wrap pd-top-100 pd-bottom-70">
        <div className="search-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                {this.state.RentalItems.length > 0 ? (
                  this.state.RentalItems.map((item, i) => (
                    <div key={i} className="single-feature style-two" style={{ display: 'flex', marginBottom: '20px' }}>
                      <div className="thumb" style={{ position: 'relative', flex: '2' }}>
                        <img
                          src={item.objects.img[0]['@attributes'].url}
                          alt="img"
                          style={{ height: '400px', width: '100%', objectFit: 'cover' }}
                          onClick={() => this.handleImageClick(item.url)}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '120px',
                            height: '60px',
                            backgroundColor: 'black',
                            boxSizing: 'border-box',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                              fontSize: '18px',
                              textAlign: 'center'
                            }}
                          >
                            For Rent
                          </span>
                        </div>
                      </div>
                      <div className="details" style={{ flex: '1', paddingLeft: '20px' }}>
                        <div className="details-wrap">
                          <h4 className="title readeal-top" style={{ fontSize: '1.2rem' }}>
                            <Link to={item.url}>{item.address.streetNumber + " " + item.address.street + " " + item.address.suburb + ", " + item.address.state + " " + item.address.postcode}</Link>
                          </h4>
                          <h5 className="price" style={{ fontSize: '1rem', color: '#333' }}>{"$ "+item.rent+" pw"}</h5>
                          <ul
                            className="info-list"
                            style={{
                              listStyleType: 'none',
                              padding: 0,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '100%',
                              paddingTop: 15
                            }}
                          >
                            <li>
                              <a className="bed" href="#">
                                <i className="fa fa-bed" style={this.iStyle} />
                              </a>
                              {item.features.bedrooms}

                            </li>
                            <li>
                              <a className="bed" href="#">
                                <i className="fa fa-bath" style={this.iStyle} />
                              </a>
                              {item.features.bathrooms}

                            </li>
                            <li>
                              <a className="bed" href="#">
                                <i className="fa fa-car" style={this.iStyle} />
                              </a>
                              {item.features.garages}

                            </li>
                          </ul>
                          <p>{item.headline}</p>
                          <ul className="contact-list" style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
                            <li style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                              <Link className="phone" to={item.url} style={{ marginRight: '10px' }} >
                                <i className="fa fa-phone" />
                              </Link>
                              <Link className="btn btn-yellow" to={item.url} style={{ padding: '5px 10px', fontSize: '0.9rem' }}>
                                View Details
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No address details found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchList);
