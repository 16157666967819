import React, { Component } from 'react';

class RealEstateSection extends Component {
  render() {
    const sectionStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '50px 20px',
      backgroundColor: '#f9f9f9',
      color: '#2c3e50',
      textAlign: 'center',
    };

    const headingPrimaryStyle = {
      fontSize: '4rem',
      fontFamily: 'Georgia, serif',
      color: '#2c3e50',
      marginBottom: '10px',
    };

    const headingSecondaryStyle = {
      fontSize: '4rem',
      fontFamily: 'Georgia, serif',
      color: '#edc987',
      marginBottom: '20px',
    };

    const subheadingStyle = {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#2c3e50',
      marginBottom: '20px',
    };

    const paragraphStyle = {
      fontSize: '1rem',
      color: '#34495e',
      marginBottom: '30px',
      maxWidth: '800px',
    };

    const buttonStyle = {
      fontSize: '1rem',
      color: '#edc987',
      border: '1px solid #edc987',
      backgroundColor: 'transparent',
      padding: '10px 20px',
      cursor: 'pointer',
      borderRadius: '5px',
      textDecoration: 'none',
    };

    return (
      <div style={sectionStyle}>
        <div>
          <span style={headingPrimaryStyle}>HELEN REALTY </span>
          {/* <span style={headingSecondaryStyle}>Real Estate</span> */}
        </div>
        <h2 style={subheadingStyle}>Real Estate Agents & Property Management Services in Adelaide</h2>
        <p style={paragraphStyle}>
          HELEN REALTY is a comprehensive real estate company located in the heart of Adelaide's central business district.
        </p>
        {/* <button style={buttonStyle}>Meet Our Team</button> */}
      </div>
    );
  }
}

export default RealEstateSection;
