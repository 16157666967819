import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Client from './section-components/client';
import BuySellTable from './section-components/buy-sell-table';
import Footer from './global-components/footer';
import Dream from './section-components/dream';
import Rentprocess from './section-components/rent-processes';
import { useTranslation } from 'react-i18next';

const Sellwith = () => {
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language;
    return <div>
        <Navbar />
        <PageHeader headertitle="Rent with Helen Realty" />
        {<Dream />}
        <Rentprocess />
        <br />
        <br />
        {/* <Professional1 /> */}
        {/* <SellHome /> */}
        {/* <Mission /> */}
        <Client type='rent'/>
        {/* <Process /> */}
        {/* <BuyOrSell /> */}
        <BuySellTable />
        <br />
        <Footer />
    </div>
}

export default Sellwith

