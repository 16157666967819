import React from 'react';
import Navbar from './global-components/navbar';
import CalltoAction from './section-components/calltoaction';
import Client from './section-components/client';
import OurPartner from './section-components/our-partner';
import NewsDetailsSection1 from './blog-components/news-details1';
import PropertySection1 from './section-components/available-peroperty1';
import ServiceTwo from './section-components/service-two';
import Matrix1 from './section-components/matrix1';
import AboutBannerSection1 from './section-components/about_banner_section1';
import PostListnew from './blog-components/post-listnew';
import SellTable from './section-components/sell-table';
import Footer from './global-components/footer';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <AboutBannerSection1 />
        <NewsDetailsSection1 />
        <Matrix1 />
        <ServiceTwo />
        {/* <AppermentSlider /> */}
        {/* <About /> */}
        {/* <RoomSpacing /> */}
        <Client PaddingTop="pd-top-60" PaddingBottom="0" />
        {/* <OurPartner /> */}
        <PostListnew />
        {/* <SellTable /> */}
        <br />
        <Footer />
    </div>
}

export default Home_V1

