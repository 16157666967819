import React from 'react';
import Navbar from './global-components/navbar';
import Footer from './global-components/footer';
import EmployeeDetail from './section-components/employee-detail';
const EmployeeDetails = () => {
    return <div>
        <Navbar />
        <EmployeeDetail />
        <Footer />
    </div>
}

export default EmployeeDetails

