import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import AvailablePositions from './section-components/available_positions';

const Poster = () => {
  let publicUrls = process.env.PUBLIC_URL + '/';
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Available Positions" />
      <AvailablePositions type='poster'/>
      <div style={{display:"flex",alignItems:'center',paddingBottom:"20px"}} className='container'>
          <img style={{width:"100%"}} src={publicUrls+'assets/img/poster.png'}></img>
      </div>
      <Footer />
    </div>
  );
}


export default Poster;