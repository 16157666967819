import React, { Component } from 'react';
import axios from 'axios'; // Import axios

class AdminData extends Component {
  constructor(props) {
    super(props);
    // Initialize state with an empty data array
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    axios.get('/api/adminData')
      .then(response => {
        // Handle success
        console.log(response.data)
        this.setState({ data: response.data });
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }

  render() {
    const { data } = this.state;
    const tableStyle = {
      width: '100%',
      borderCollapse: 'collapse',
      color: '#FFFFFF',
      backgroundColor: '#0A1128',
    };

    const headerStyle = {
      background: '#0e1130',
      border: '1px solid #1A2939',
      padding: '12px 15px',
      textAlign: 'left',
      color: '#FFFFFF',
      fontSize: '16px'
    };

    const cellStyle = {
      border: '1px solid #1A2939',
      padding: '12px 15px',
      textAlign: 'left',
      color: '#FFFFFF',
      fontSize: '14px'
    };

    return (
      <>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={headerStyle}>First Name</th>
              <th style={headerStyle}>Last Name</th>
              <th style={headerStyle}>Email</th>
              <th style={headerStyle}>Phone</th>
              <th style={headerStyle}>Joined Date</th>
              <th style={headerStyle}>Joined Page</th>
              <th style={headerStyle}>IP</th>
              <th style={headerStyle}>Interest</th>
              <th style={headerStyle}>Property Viewed</th>
              <th style={headerStyle}>Property Viewed Count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>{item.ClientFirstName}</td>
                <td style={cellStyle}>{item.ClientLastName}</td>
                <td style={cellStyle}>{item.ClientEmail}</td>
                <td style={cellStyle}>{item.ClientPhoneNumber}</td>
                <td style={cellStyle}>{item.ClientJoinedDate}</td>
                <td style={cellStyle}>{item.ClientJoinedPage}</td>
                <td style={cellStyle}>{item.ClientIP}</td>
                <td style={cellStyle}>{item.ClientInterest}</td>
                <td style={cellStyle}>{item.PropertyViewed}</td>
                <td style={cellStyle}>{item.PropertySearchedCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default AdminData;