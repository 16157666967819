import React from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';

const Matrix1 = ({ publicUrl = process.env.PUBLIC_URL + '/', imagealt = 'image' }) => {
    // let data1 = sectiondata.explore;

    const data = {
        // title: data1.title || 'Your Title Here',
        // slogen: data1.slogen || 'Your Slogan Here',
        items: [
            { image: 'assets/img/banner/首页.png', url: '/buy', title: 'Market Square', price: 'View project', content: '' },
            { image: 'assets/img/banner/首页2.png', url: '/rent', title: 'Coast Henley Beach south', price: 'View project', content: '' },
            { image: 'assets/img/banner/frontpage.png', url: '/sell', title: 'Verde', price: 'View project', content: '' },
            { image: 'assets/img/banner/首页.png', url: '/manage', title: 'Rose Park', price: 'View project', content: '' },
            { image: 'assets/img/banner/首页.png', url: '/projects', title: 'Prospect', price: 'View project', content: '' }
        ]
    };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gap: '2px', // Reduced gap to make the distance between items closer
        width: '80vw', // Reduced width to make it narrower
        height: '80vh', // Adjust height as needed
        padding: '10px',
        margin: '0 auto' // Center the grid
    };

    const gridItemStyle = {
        backgroundColor: '#fff',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        textAlign: 'center',
        textShadow: '0 0 5px rgba(0,0,0,0.5)',
        transform: 'scale(0.95)', // Scale down each grid item slightly less to make them larger
        textDecoration: 'none'
    };

    const itemStyles = [
        { gridColumn: 'span 2', gridRow: 'span 1' },
        { gridColumn: 'span 2', gridRow: 'span 1' },
        { gridColumn: 'span 2', gridRow: 'span 1' },
        { gridColumn: 'span 1', gridRow: 'span 1' },
        { gridColumn: 'span 1', gridRow: 'span 1' }
    ];

    const overlayStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.3)'
    };

    const thumbStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.5)', // Apply a dark filter
        transition: 'filter 0.3s ease' // Smooth transition for hover effect
    };

    const hoverThumbStyle = {
        filter: 'brightness(0.8) sepia(0.1) hue-rotate(10deg) saturate(1.2)'
    };

    const detailsStyle = {
        position: 'absolute',
        zIndex: 1,
        color: 'white' // Set text color to white
    };

    const priceStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
        color: 'white', // Set text color to white
        padding: '5px',
        borderRadius: '5px',
        display: 'inline-block',
        marginTop: '10px'
    };

    const sectionTitleStyle = {
        textAlign: 'left',
        marginLeft: '13%' // Adjust this value to move the title slightly to the right
    };

    return (
        <div>
            <div className="section-title" style={sectionTitleStyle}>
                <h3 className="title">{data.title}</h3>
                {/* <h6 className="title">Found out your dream House</h6> */}
            </div>
            <div style={gridContainerStyle}>
                {data.items.map((item, index) => (
                    <Link
                        key={index}
                        to={item.url}
                        style={{ ...gridItemStyle, ...itemStyles[index] }}
                        onMouseEnter={(e) => {
                            e.currentTarget.querySelector('img').style.filter = hoverThumbStyle.filter;
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.querySelector('img').style.filter = thumbStyle.filter;
                        }}
                    >
                        <div style={overlayStyle}></div>
                        <img src={publicUrl + item.image} alt={imagealt} style={thumbStyle} />
                        <div style={detailsStyle}>
                            <h4 style={{ color: 'white' }}>{item.title}</h4>
                            <h5 style={priceStyle}>{item.price}</h5>
                            <span>{item.content}</span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Matrix1;
