import React, { useState, useEffect } from 'react';
import OwnerInfo from './OwnerInfo';
import ImageGallery from './ImageGallery.js';
import { getSavedAddressDetails } from './addressService'; // 导入 getSavedAddressDetails
import sectiondata from '../../data/sections.json'; // 导入 sectiondata
import { useLocation } from 'react-router-dom';
import { getListing } from '../../data/api';
import emailjs from '@emailjs/browser';

const PropertyDetails = ({ getHeaderTitle, setShowNav }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [addressDetails, setAddressDetails] = useState(null);
  const [imgList, setImgList] = useState([]);
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: "",
    message: '',
    pageUrl: window.location.href
  })
  let publicUrl = process.env.PUBLIC_URL + '/';
  const queryParams = new URLSearchParams(location.search);
  const uniqueID = queryParams.get('uniqueID');
  const type = queryParams.get('type') || 'sell';
  const [currentDetail, setCurrentDetail] = useState({})
  const googleMapApiKey = 'AIzaSyCW7hPnNqKXjZZOZ2mpPoR7xlTKpL4LDIY'

  function formatAddress(address) {
    const {
      streetNumber,
      street,
      suburb,
      state,
      postcode,
      country
    } = address;

    return `${streetNumber} ${street}, ${suburb}, ${state} ${postcode} ${country}`;
  }


  useEffect(() => {
    console.log('Effect ran on mount!');

    const fetchData = async () => {
      const savedAddressDetails = getSavedAddressDetails();
      setAddressDetails(
        savedAddressDetails.length > 0 ? savedAddressDetails[0] : sectiondata.searchlist.items[0]
      );
      const res = await getListing();
      console.log('res', res);

      // 格式化日期并排序
      const sortedRes = res.sort((a, b) => {
        const formatAndParseDate = (dateStr) => new Date(dateStr.replace(/-/g, " ").replace(" ", "T").replace("T", " ") + "Z").getTime();
        return formatAndParseDate(b['@attributes'].date) - formatAndParseDate(a['@attributes'].date);
      });

      const latestItems = sortedRes
      let residentialItems = [];

      latestItems.forEach((item) => {
        if (type === 'rent') {
          if (item.rental) {
            residentialItems = residentialItems.concat(item.rental);
          }
        } else {
          if (item.residential) {
            residentialItems = residentialItems.concat(item.residential);
          }
        }
      });

      const uniqueResidentialItems = residentialItems.filter((item, index, self) =>
        index === self.findIndex((t) => t.uniqueID === item.uniqueID)
      );
      console.log(uniqueResidentialItems,'uniqueResidentialItems');
      const currentDetails = uniqueResidentialItems.filter(item => item.uniqueID === uniqueID)[0];

      console.log(currentDetail, 'currentDetail');

      if (currentDetail) {
        setCurrentDetail(currentDetails);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('currentDetail updated:', currentDetail);

    if (Object.keys(currentDetail).length > 0) {
      currentDetail.listingAgent = currentDetail?.listingAgent?.filter(item => item.name) || [];
      currentDetail.listingAgent.forEach(item => {
        if (item.name) {
          const name = item.name.split(' ');
          item.url = `${publicUrl}assets/img/team/${name.join('_')}.png`;
          item.name2 = name[0];
        }
      });
      const imglist = currentDetail.objects.img.map(item => item['@attributes'].url).filter(item => item !== null && item!==undefined);
      // const map = new Map();
      // currentDetail.listingAgent = currentDetail?.listingAgent?.filter(v => !map.has(v.id) && map.set(v.id, v));
      setImgList(()=>{
        return imglist
      })
      setAddressDetails((cur) => {
        // const description = currentDetail?.description.length > 0 ? currentDetail?.description?.split(/\n\s*\n/) : '';
        const description = currentDetail?.description.length > 0 ? currentDetail?.description.replace(/\n/g, '<br>').replace(/ {2,}/g, '&nbsp;') : '';
      
        return {
          title: currentDetail.address.streetNumber + " " + currentDetail.address.street + " " + currentDetail.address.suburb + "," + currentDetail.address.state + " " + currentDetail.address.postcode ,
          features: currentDetail.features,
          shortDescription: description[0],
          fullDescriptionParagraphs: description,
          listingAgent: currentDetail.listingAgent || [],
          floorplan: currentDetail.objects.floorplan,
          imglist: imglist,
          addressStr: formatAddress(currentDetail.address),
          priceView:currentDetail?.priceView
        };
      });
      getHeaderTitle( currentDetail.address.streetNumber + " " + currentDetail.address.street + " " + currentDetail.address.suburb + "," + currentDetail.address.state + " " + currentDetail.address.postcode);
    }
  }, [currentDetail]);


  const handleToggleDescription = () => setShowFullDescription(!showFullDescription);

  let imagealt = 'image';


  const shortDescription = addressDetails
    ? addressDetails.shortDescription || `Welcome to your dream home! This luxurious double-storey villa boasts 4 bedrooms, 3 bathrooms, 4 toilets, and 2 living rooms, with a double garage. The house is fully furnished with high-end, modern amenities, designed for maximum comfort and convenience. This house is perfect for families looking to live with their parents.`
    : `Welcome to your dream home! This luxurious double-storey villa boasts 4 bedrooms, 3 bathrooms, 4 toilets, and 2 living rooms, with a double garage. The house is fully furnished with high-end, modern amenities, designed for maximum comfort and convenience. This house is perfect for families looking to live with their parents.`;

  const fullDescriptionParagraphs = addressDetails?.fullDescriptionParagraphs
    ? addressDetails.fullDescriptionParagraphs
    : [
      `The location of this property is second to none, with a peaceful and serene environment that is close to everything you need. The community is surrounded by natural beauty, including numerous parks and conservation areas, and is adjacent to the Park Holme library. You'll find various entertainment venues, popular fitness centers, and some of the most beautiful beaches in the area, such as Glenelg and Henley beach, just a stone's throw away.`,
      `For your convenience, there are many shopping centers nearby, including the Park Holme shopping center, Castle Plaza, and the Westfield Marion shopping center, offering an abundance of options for you to enjoy your life to the fullest.`,
      `Not to mention, the area is home to some of the best schools in the region, including the prestigious Westminster School, providing excellent education options for your children.`,
      `This property is truly a gem, with everything you need to enjoy life to the fullest. Don't miss out on the opportunity to make it your own! Contact us to schedule a viewing and take the first step towards your dream home!`,
      `Features you will love include:`,
      `• Torrens title`,
      `• Two color schemes are available for the interior decoration design.`,
      `• High-end kitchen equipped with ROBAM range hood, cooktop and steam oven, and optional BOSCH or SMEG oven and dishwasher`,
      `• Wooden flooring combined with tiled flooring throughout the house for easy cleaning and maintenance`,
      `***Please note that the images provided above are for display purposes only and are intended to serve as a reference for selecting interior design styles for your property***`
    ];

    const apartmentDescription = `
    Welcome to the pinnacle of urban sophistication at 701/ONE Flinders Apartment, 
    where an exceptional living experience awaits, transcending the ordinary.
    
    Upon entering, you'll be greeted by a spacious retreat designed to inspire. 
    Soft, ambient lighting dances across sleek wooden floors, while soaring ceilings 
    enhance the airy feel, inviting you to relax in style.

    Unleash your inner chef in the state-of-the-art kitchen, featuring premium 
    European Miele appliances and luxurious marble countertops. Each morning, bask 
    in natural light as you enjoy a freshly cup of coffee in this culinary sanctuary.

    Step outside to your private balcony, where stunning panoramic views and 
    generous outdoor space invite you to unwind. The outdoor area seamlessly 
    connects to the exclusive garden on the 7th floor, offering a tranquil retreat 
    where you can bask in the sun's rays every day.

    Located just moments from Rundle Mall, you'll have access to a plethora of 
    shopping and dining experiences. Convenient transport options ensure that the 
    heartbeat of the city is always within reach.

    Families will appreciate the close proximity to esteemed educational institutions, 
    including the University of South Australia and the University of Adelaide, along 
    with top-tier high schools like Adelaide High School and Adelaide Botanic High School.

    Highlights of 701/248 Flinders Street, Adelaide:
    ✅ Low vacancy rate for maximised investment potential
    ✅ Spacious 2 bedrooms, both with built-in robes
    ✅ Bedroom feature windows, providing ample natural ventilation to enhance living comfort.
    ✅ Premium Miele kitchen appliances
    ✅ Floor-to-ceiling windows flooding the space with natural light
    ✅ Double-glazed windows for noise reduction
    ✅ Private balcony with breathtaking views
    ✅ Ducted reverse cycle air conditioning
    ✅ Built-in fridge for a seamless kitchen design
    ✅ Island kitchen with a beach-inspired design for a relaxed, modern feel
    ✅ Secure undercover parking

    Contact Vivian Zhang at 0401364784 or Louise Lim at 0474 577 637 today to secure 
    your slice of urban paradise. Don't miss out!
`;
  const descriptionStyle = {
    maxHeight: showFullDescription ? '100%' : '200px',
    overflow: 'hidden',
    transition: 'max-height 0.5s ease'
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form data:', formData);
    const data = { ...formData }
    try {
      // const response = await axios.post('/api/submit-property-appraisal', formData);
      emailjs
        .send(
          'service_0qn969g',
          'template_72sut38',
          data,
          'PcHzeyHQ7091mGvgC'
        )
        .then((result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setFormData(prevState => {
              const clearedState = {};
              Object.keys(prevState).forEach(key => {
                clearedState[key] = ''; // 或者 undefined
              });
              alert('Form submitted successfully!');
              return clearedState;
            });
          }
        }
        );
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting the form.');
    }
  };

  const fs20 = {
    fontSize: "20px"
  }
  const fs18 = {
    fontSize: "18px"
  }


  return (
    <div className="property-details-area">
      <div className="bg-gray pd-top-100 pd-bottom-90">
        <div className="container" style={{ minWidth: "1500px" }}>
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div style={{ display: 'flex', gap: '20px' }}>
                <div className="property-details-slider-info" style={{ width: '30%', display: "flex", flexDirection: "column", alignContent: "center" }}>
                  <div>
                    <h5 style={{ backgroundColor: 'navy', color: 'white', display: 'inline-block', padding: '5px', marginRight: '10px' }}>
                      <span>{type === 'rent' ? 'For Rent' : 'For Sale'}</span>
                    </h5>
                    <h4 style={{ display: 'inline-block', fontSize: '26px' }}>
                      <span>Contact Agent</span>
                    </h4>
                    <h5 style={{ fontSize: '28px',marginTop:"40px",lineHeight:"42px" }}>{addressDetails ? addressDetails.title : '4 Davidson Avenue, Park Holme SA 5043, Australia'}</h5>
                    <div className="row" style={{marginTop:"20px"}}>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info" style={{ fontSize: '28px' }}>
                          {/* <h5>Bedrooms</h5> */}
                          <p><i className="fa fa-bed" />{addressDetails ? addressDetails.features.bedrooms : '4'}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info" style={{ fontSize: '28px' }}>
                          {/* <h5>Bathrooms</h5> */}
                          <p><i className="fa fa-bath" />{addressDetails ? addressDetails.features.bathrooms : '2'}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="single-property-info" style={{ fontSize: '28px' }}>
                          {/* <h5>Parking</h5> */}
                          <p><i className="fa fa-car" />{addressDetails ? addressDetails.features.garages : '01 Indoor'}</p>
                        </div>
                      </div>
                      {/* <div className="col-md-3 col-sm-6">
                        <div className="single-property-info" style={{ fontSize: '22px' }}>
                   
                          <p><img style={{ width: '22px' }} src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} />{addressDetails ? addressDetails.area : '1,038 sq'}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div style={{fontSize:"28px",fontWeight:"600",color:"var(--heading-color)",marginTop:"40px"}}>
                    {addressDetails?.priceView || 'contact agent'}
                  </div>
                  {/* <div style={{ marginTop: "20px", color: '#000', fontWeight: "600", fontSize: "22px" }}>Best Offer by Tuesday 10th September at 12pm(USP)</div>
                  <div style={{ marginTop: "20px", fontSize: '20px' }}>Inspection tomorrow 11:30 am <span style={{ color: "navy", fontWeight: "600" }}>View all</span></div> */}
                  {/* <div className="property-info mb-5">
                
                  </div> */}
                </div>
                <div style={{ overflow: 'hidden', width: 'calc(70% - 20px)', marginRight: '-20px' }}>
                  <div style={{ overflow: 'hidden' }}>
                    <ImageGallery publicUrl={publicUrl} imagealt={imagealt} imglist={imgList} setShowNav={setShowNav} />
                  </div>
                  <div style={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
                    {
                      addressDetails?.imglist && addressDetails?.imglist.slice(0, 3).map(items => {
                        return (
                          <div key={items} style={{ marginRight: '6px', flex: '33%' }}><img src={items} style={{ width:"100%",height:"100%",objectFit:"cover" }}></img></div>
                        )
                      })
                    }
                  </div>
                </div>

              </div>


              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4 style={{ fontSize: "28px" }}>Property Description</h4>
                <div style={{ ...descriptionStyle, fontSize: '20px' }}>
                  {/* <p>{fullDescriptionParagraphs}</p> */}
                  <p dangerouslySetInnerHTML={{__html:fullDescriptionParagraphs}}></p>
                  {/* {showFullDescription && fullDescriptionParagraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))} */}
                </div>
                <a onClick={handleToggleDescription} style={{ cursor: 'pointer', color: '#006400', textDecoration: 'underline' }}>
                  {showFullDescription ? 'Read Less' : 'Read More'}
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5" style={fs20} >
                {
                  addressDetails?.listingAgent && addressDetails?.listingAgent.map(item => {
                    return <OwnerInfo key={item.url} imgSrc={item.url} imgAlt={item.name2} name={item.name} title='Sales Manager' />
                  })
                }
                <div className="contact">
                  <h6 style={fs20} >Contact Us</h6>
                  <form onSubmit={handleSubmit}>
                    <div className="rld-single-input">
                      <input style={fs18} type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
                    </div>
                    <div className="rld-single-input">
                      <input style={fs18} type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="rld-single-input">
                      <input style={fs18} type="text" name="message" placeholder="Messages" value={formData.message} onChange={handleChange} />
                    </div>
                    <button className="btn btn-yellow" type="submit" style={fs20}>Send Messages</button>
                  </form>

                </div>
                <div className="contact-info">
                  <h6 className="mb-3" style={{ fontSize: "22px" }}>Contact Info</h6>
                  <div className="media">
                    <div className="media-left">
                      <img src={publicUrl + "assets/img/icons/1.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <p style={fs20}>Address</p>
                      <span style={fs18}>{'Level 1,251 Hutt Street,Adelaide SA 5000'}</span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <p style={fs20}>Phone</p>
                      <span style={{ ...fs18, display: 'block' }}>+61 0415 365 888 Helen</span>
                      {/* <span style={{ ...fs18, display: 'block' }}>+61 401 364 784 Vivian</span> */}
                    </div>
                  </div>
                  <div className="media mb-0">
                    <div className="media-left">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <p style={fs20}>Email</p>
                      <span style={{ ...fs18, display: 'block' }}>admin@helenrealtysa.com</span>
                      {/* <span style={{ ...fs18, display: 'block' }}>vivian.zhang@helenrealtysa.com</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">

            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Address</h4>
              {addressDetails?.addressStr && <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=${encodeURIComponent(addressDetails.addressStr)}`}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>}
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Floor Plan</h4>
              <div className="thumb">
                {
                  addressDetails?.floorplan && addressDetails?.floorplan.map(item => {
                    return item['@attributes']?.url && <img key={item['@attributes'].url} src={item['@attributes'].url} alt={imagealt} />
                  })
                }
                {/* <img src={publicUrl + "assets/img/others/图片1.png"} alt={imagealt} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
