// addressService.js
import sectiondata from '../../data/sections.json'; // 导入 sectiondata

let savedAddressDetails = null;

function saveAddressDetails(addressDetails) {
  savedAddressDetails = addressDetails;

  // 使用 localStorage 保存数据
  localStorage.setItem('addressDetails', JSON.stringify(addressDetails));

  // 从 localStorage 读取并更新 sectiondata
  let sections = JSON.parse(localStorage.getItem('sections')) || sectiondata;

  // 替换 searchlist items 中的地址
  if (sections.searchlist && sections.searchlist.items) {
    sections.searchlist.items.forEach((item, index) => {
      if (index === 0) { // 假设要替换第一个 item's address
        item.title = addressDetails.title || item.title;
        item.image = addressDetails.image || item.image;
        item.icon = addressDetails.icon || item.icon;
        item.url = addressDetails.url || item.url;
        item.olderprice = addressDetails.olderprice || item.olderprice;
        item.newerprice = addressDetails.newerprice || item.newerprice;
        item.area = addressDetails.area || item.area;
        item.authorname = addressDetails.authorname || item.authorname;
        item.cat = addressDetails.cat || item.cat;
        item.features = addressDetails.features || item.features;
      }
    });
  }

  // 保存更新后的 sections 到 localStorage
  localStorage.setItem('sections', JSON.stringify(sections));

  console.log('Address details saved to localStorage');
}

function getSavedAddressDetails() {
  // 从 localStorage 读取地址详情
  // return JSON.parse(localStorage.getItem('addressDetails'));
  const details = localStorage.getItem('savedAddressDetails');
  return details ? JSON.parse(details) : [];
}

export {
  saveAddressDetails,
  getSavedAddressDetails,
};
