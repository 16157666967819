import axios from 'axios';

const API_KEY = 'AIzaSyCW7hPnNqKXjZZOZ2mpPoR7xlTKpL4LDIY'; // 替换为你的谷歌API密钥
const BASE_URL = 'https://maps.googleapis.com/maps/api/place';

const apiClient = axios.create({
    baseURL: BASE_URL, // 使用环境变量

  });

const getGoogleAutocompleteSuggestions = async (input) => {
  try {
    const response = await apiClient.get(`/autocomplete/json?input=${input}&key=${API_KEY}&components=country:au`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Google Autocomplete suggestions:', error);
    throw error;
  }
};

export default {
  getGoogleAutocompleteSuggestions,
};