import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AdminData from './section-components/admin-data';
import Footer from './global-components/footer';

const helen_admin = () => {
    return(
    <>
    <div>
        <Navbar />
        <PageHeader headertitle="Helen Data" />
        <AdminData />
        <Footer />
    </div>
    </>
)}

export default helen_admin