import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { withTranslation } from 'react-i18next';

class Mission extends Component {
  // yuchen
  render() {
    let data = sectiondata.mission
    const { t } = this.props;
    return <div className="mission-vission-area pd-bottom-70">
      <div className="container" style={{maxWidth:"1300px"}}>
        {/* <div className='mission_title' style={{textAlign: 'center'}}><h1>We <span style={{color:"orange"}}>Specialise</span> in</h1></div> */}
        <div className='mission_title' style={{ textAlign: 'center', paddingTop: 50 }}><h1>Our <span style={{ color: "#edc987" }}>Values</span></h1></div>
        <div className="row justify-content-center" style={{justifyContent:"center",gap:"10px",padding:"10px"}}>
          {data.items.map((item, i) =>
            <div key={i} className="col-xl-3 col-lg-3 col-sm-5" style={{padding:"20px",border:"1px solid rgb(237, 201, 135)",borderRadius:"8px"}}>
              <div className="single-intro text-center">
                <div className="text">
                  {item.number}
                </div>
                <div className="details">
                  <h4 className="title"><a href="#">{t(item.title)}</a></h4>
                  <p>{t(item.content)}</p>
                </div>
              </div>
            </div>
          )}


        </div>
      </div>
    </div>
    {/* choose us start */ }

  }
}

export default withTranslation()(Mission);
