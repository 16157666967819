import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';


import CareerSection1 from './section-components/career_section1';
import CareerSection2 from './section-components/career_section2';
import CareerSection3 from './section-components/career_section3';
import AvailablePositions from './section-components/available_positions';
import CareerSection4 from './section-components/career_section4';
import CareerSection5 from './section-components/career_section5';
import CareerSection6 from './section-components/career_section6';
import CareerSection7 from './section-components/career_section7';
import CareerSection8 from './section-components/career_section8';


const About = () => {
    return <div style={{position:'relative'}}>
        <Navbar />
        <PageHeader headertitle="Career" />

        {/* Yuchen ADD Section1  */}
        <CareerSection1 />

        {/* Yuchen ADD Section1 <Mission /> */}
        <CareerSection2 />
        <div style={{height:"60px"}}></div>
        {/* career opportunities */}
        <CareerSection3 />
        <AvailablePositions/>
        {/* <div style={{position:'absolute',top:'20%',left:'-2300px', background:"#1f263c",width:"5000px",height:"600px",zIndex:'-1',transform: 'rotate(-30deg)'}}></div> */}
         
        {/* values */}
        {/* <CareerSection5 /> */}

        {/* express of interest */}
        {/* <CareerSection4 /> */}

        {/* <CareerSection6 /> */}
        {/* winning team */}
        {/* <CareerSection7 /> */}
        {/* end */}
        {/* <CareerSection8 /> */}
        
        
        <Footer />
    </div>
}

export default About

