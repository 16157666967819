import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AdminLogin from './section-components/admin-login';
import Footer from './global-components/footer';

const admin_login = () => {
    return (
        <>
            <div>
                <Navbar />
                <PageHeader headertitle="Helen Login" />
                <br />
                <AdminLogin />
                <br />
                <Footer />
            </div>
        </>
    )
}

export default admin_login