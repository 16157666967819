import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next'; 

class Page_header extends Component {

    render() {
        const { t } = this.props;
        let HeaderTitle = this.props.headertitle;
        let publicUrl = process.env.PUBLIC_URL + '/'
        let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
        const inlineStyle = {
            backgroundImage: 'url(' + publicUrl + 'assets/img/bg/helen.png)',
        }

        const overlayStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // 黑色半透明
            zIndex: 1,
        }

        const contentStyle = {
            position: 'relative',
            zIndex: 2,
        }

        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div style={overlayStyle}></div>
                <div className="container" style={contentStyle}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h1 className="page-title" style={{ color: "white" }}>{t(HeaderTitle)}</h1>
                                <ul className="page-list">
                                    <li><Link to="/">{t('home')}</Link></li>
                                    <li>{t(Subheader)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Page_header);