import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from 'react-i18next'; 
class AboutUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let image1 = publicUrl + 'assets/img/companyphoto.png';
    let image2 = publicUrl + 'assets/img/anotherphoto.png'; // 添加更多图片路径
    let imagealt = 'image'
    const career_data = {
      subtitle: "Our Story",
      title: "We a comprehensive real estate company",
      content: "9",
    }
    const { t } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000, // 3 seconds
      pauseOnHover:false,
    };

    return (
      <div className="about-area pd-bottom-90" style={{ paddingTop: '50px'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0" style={{alignSelf:'center'}}>
              <Slider {...settings}>
                <div>
                  <img className="shadow-img" src={image1} alt={imagealt} style={{ width: '100%' }} />
                </div>
                <div>
                  <img className="shadow-img" src={image1} alt={imagealt} style={{ width: '100%' }} />
                </div>
                {/* 可以添加更多的图片 */}
              </Slider>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                <h5 className="sub-title">{career_data.subtitle}</h5>
                {/* <h2 className="title" style={{fontSize:"24px"}}>{career_data.title}</h2> */}
                <p>{t(career_data.content)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutUs);