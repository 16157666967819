import React, { useState, useEffect } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';

const BuySellTable = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        propertyAddress: '',
        preferenceType: '',
        message: '',
        currentWindowPage: window.location.href, // Set the current page URL
        ip: ''
    });

    useEffect(() => {
        // Fetch the IP address when the component mounts
        const fetchIP = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setFormData(prevFormData => ({ ...prevFormData, ip: response.data.ip }));
            } catch (error) {
                console.error('Failed to fetch IP address:', error);
            }
        };
        fetchIP();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Submitting form data:', formData);
        const data = {...formData}
        try {
            // const response = await axios.post('/api/submit-property-appraisal', formData);
            emailjs
            .send(
                'service_0qn969g',
                'template_bt80tqb',
                data,
                'PcHzeyHQ7091mGvgC'
            )
            .then((result) => {
                console.log(result.text);
                if (result.text === "OK") {
                    setFormData(prevState => {
                        const clearedState = {};
                        Object.keys(prevState).forEach(key => {
                          clearedState[key] = ''; // 或者 undefined
                        });
                        alert('Form submitted successfully!');
                        return clearedState;
                      });
                }
            }
            );
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting the form.');
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <form className="contact-form-wrap contact-form-bg p-4" onSubmit={handleSubmit}>
                        <h4>Request a Property Appraisal</h4>
                        <p>Please take a moment to fill out the form.</p>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">First Name</label>
                                <input type="text" className="form-control" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Last Name</label>
                                <input type="text" className="form-control" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Email *</label>
                                <input type="email" className="form-control" name="email" placeholder="Email" value={formData.email} required onChange={handleChange} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Phone</label>
                                <input type="tel" className="form-control" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Property Address *</label>
                            <input type="text" className="form-control" name="propertyAddress" placeholder="Select an Address" value={formData.propertyAddress} required onChange={handleChange} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Choose an Appraisal Type *</label>
                            <select className="form-select" name="preferenceType" required value={formData.preferenceType} onChange={handleChange}>
                                <option value="">-- Select Appraisal Type --</option>
                                <option value="buy">Buy</option>
                                <option value="sell">Sell</option>
                                <option value="lease">Lease</option>
                                <option value="rent">Rent</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Leave us a message...</label>
                            <textarea className="form-control" name="message" rows="3" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success" style={{ backgroundColor: '#edc987', borderColor: '#edc987' }}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BuySellTable;



// import React from 'react';
// const BuySellTable = () => {
//     return <div className="container">
//         <div className="row justify-content-center">
//             <div className="col-lg-12">
//                 <form className="contact-form-wrap contact-form-bg p-4">
//                     <h4>Request a Property Appraisal</h4>
//                     <p>Please take a moment to fill out the form.</p>
//                     <div className="row">
//                         <div className="col-md-6 mb-3">
//                             <label className="form-label">First Name</label>
//                             <input type="text" className="form-control" placeholder="First Name" />
//                         </div>
//                         <div className="col-md-6 mb-3">
//                             <label className="form-label">Last Name</label>
//                             <input type="text" className="form-control" placeholder="Last Name" />
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-md-6 mb-3">
//                             <label className="form-label">Email *</label>
//                             <input type="email" className="form-control" placeholder="Email" required />
//                         </div>
//                         <div className="col-md-6 mb-3">
//                             <label className="form-label">Phone</label>
//                             <input type="tel" className="form-control" placeholder="Phone" />
//                         </div>
//                     </div>
//                     <div className="mb-3">
//                         <label className="form-label">Property Address *</label>
//                         <input type="text" className="form-control" placeholder="Select an Address" required />
//                     </div>


//                     <div className="mb-3">
//                         <label className="form-label">Choose an Appraisal Type *</label>
//                         <select className="form-select" required>
//                             <option value="">-- Select Appraisal Type --</option>
//                             <option value="buy">Buy</option>
//                             <option value="sell">Sell</option>
//                             <option value="lease">Lease</option>
//                             <option value="rent">Rent</option>
//                         </select>
//                     </div>
//                     <div className="mb-4">
//                         <label className="form-label">Leave us a message...</label>
//                         <textarea className="form-control" rows="3" placeholder="Message"></textarea>
//                     </div>
//                     <div className="text-center">
//                         <button type="submit" className="btn btn-success" style={{ backgroundColor: '#edc987', borderColor: '#edc987' }}>Submit</button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     </div>


// }
// export default BuySellTable