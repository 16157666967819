import sectiondata from '../../data/sections.json';
import { useLocation } from 'react-router-dom';
const EmployeeDetail = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const index = queryParams.get('index');
    const data = sectiondata.team.items[index];
    const names = data.name.split(' ');
    console.log(data,'data');
    console.log(names);
    const style1 = {
        display: 'flex',
        flex:'1',
        flexWrap:"wrap"
    }
    const style2 = {
        height: '145px',
        backgroundColor: 'var(--heading-color)',
    }
    const askStyle = {
        color:'#36454f',
        border: '1px solid var(--paragraph-color)',
        padding: '8px',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '5px',
    }
    return (
        <div className="employee-detail-wrap">
            <div style={style2}></div>
            <div style={style1} className='row'>
                <div className="detail-content col-sm-6 col-md-6">
                    <div>
                        <div className='detail-name'>
                            <h2 style={{lineHeight:'1',color:'var(--paragraph-color)',fontFamily:'"Source Serif 4",sans-serif'}}>Hi, I'm</h2>
                            {
                                names.map((item,index) => {
                                    return <h2 style={{lineHeight:'1',color:index===0?'var(--main-color-one)':'var(--paragraph-color)',fontFamily:'"Source Serif 4",sans-serif'}}>{item}</h2>
                                })
                            }
                        </div>
                        <h2 style={{color:'var(--paragraph-color)',margin:'24px 0'}}>{data.designation}</h2>
                        <p style={{lineHeight:"20px"}}>{data.description}</p>
                        <div style={{...askStyle,borderColor:'var(--main-color-one)',display:'flex',alignItems:'center',justifyContent:'center'}}><img style={{width:'1rem',marginRight:'8px'}} src='/assets/img/icons/32.png'></img>Email</div>
                    </div>
                    
                    <div style={{...askStyle,marginTop:'10px'}}>Ask a Question</div>
                </div>
                <div className="detail-image col-sm-6 col-md-6">
                    <img src={publicUrl + data?.image}></img>
                </div>
            </div>

        </div>
    );
}
export default EmployeeDetail