import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';

class ServiceTwo extends Component {
  constructor(props){
    super(props);
    const initialData = props.i18n.language === 'zh' ? sectiondata.servicetwoZh : sectiondata.servicetwo;
    this.state = {
      data:initialData,
    };
  }

    render() {
        const { t, i18n } = this.props;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        console.log(i18n);

        const inlineStyle = {
               backgroundImage: 'url('+publicUrl+'/assets/img/bg/service.png)',
               marginTop: '100px',
        }


    return <div className="service-area service-area-about mg-bottom-100 pb-xl-5 pd-0" style={ inlineStyle }>
        <div className="container">
          <div className="section-title">
            <h5 className="sub-title">{ this.state.data.subtitle }</h5>
            <h2 className="title">{ this.state.data.title }</h2>
            <p>{ this.state.data.content1 }<br /> { this.state.data.content2 }</p>
          </div>
          <div className="service-slider-2 row pb-xl-5 pd-0">
            { this.state.data.items.map( ( item, i )=>
                <div key={ i } className="item">
                  <div className="single-intro text-center">
                    <div className="thumb">
                      <img src={publicUrl+item.icon} alt={ imagealt } />
                    </div>
                    <div className="details">
                      <h4 className="title"><a href={ item.url }>{ t(item.title) }</a></h4>
                      <p>{ t(item.content1) } <br /> { item.content2 }</p>
                      <a className="read-more" href={ item.url }>{ item.btntxt }</a>
                    </div>
                  </div>
              </div>

             ) }
            
          </div>
        </div>
      </div>
        }
}

export default withTranslation()(ServiceTwo);
