import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import newsdetails from '../../data/single-blogdata.json';
import Comments from './comments';
class NewsDetails1 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = newsdetails.singlepostdata

        return (
            <div>
                <div className="news-details-area">
                    <div className="container">

                        <div className="row justify-content-center pd-top-70">
                            <div className="col-lg-8">
                                <div className="news-details-wrap">
                                    <h3 className="title1">{data.title}</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis enim vel leo laoreet, quis sodales purus blandit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque blandit justo et erat aliquam, vitae suscipit neque sagittis. Nullam auctor, nisi eu imperdiet vehicula, arcu purus efficitur tellus, nonporttitor velit mi eget magna. Suspendisse a nisi nulla. Nulla luctus porttitor orci, a fermentum diam vehicula sed. Nullam luctus, enim cursus ultricies porta, elit massa pretium lacus, a accumsan odio libero sed dui. Donec vel condimentum metus.</p>
                                </div>
                                {/* <Comments /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsDetails1;
