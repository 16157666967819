import axios from "axios";
const baseURL = "https://api.helen.cyberate.com.au/";

//Get list of all properties
export var getListing = async (
) => {

    let result = await axios({
        method: "get",
        url: baseURL + "readxml.php",
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });

    console.log("add", result)
    return result;
};


//Delete Favorite
//Admin.Favorite.DeleteFavorite
export var DeleteFavorite = async (


    id,

) => {
    let formData = new FormData();

    formData.append("id", id);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Favorite.DeleteFavorite",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};
