import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const ImageGallery = ({ publicUrl, imagealt, imglist = [], setShowNav }) => {
  let publicUrls = process.env.PUBLIC_URL + '/';

  const images = [
  ];
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showImgList, setShowImgList] = useState(images);
  useEffect(() => {
    setShowImgList(() => {
      return imglist.length > 0 ? imglist : images
    });
  }, [imglist]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", right:"10px",zIndex:"10" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left:"10px",zIndex:"10" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const movePrevRequest = () => {
    setIndex((prevIndex) => (prevIndex + showImgList.length - 1) % showImgList.length);
  };

  const moveNextRequest = () => {
    console.log(112233,index);
    setIndex((prevIndex) => (prevIndex + 1) % showImgList.length);
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {showImgList.map((image, indexs) => (
          <div className="item" key={indexs}>
            <img
              src={image}
              onClick={() => {
                setIsOpen(true);
                setShowNav(false)
                setIndex(indexs);
              }}
            />
          </div>
        ))}
        {isOpen && (
          <Lightbox
            mainSrc={showImgList[index]}
            nextSrc={showImgList[(index + 1) % showImgList.length]}
            prevSrc={showImgList[(index + showImgList.length - 1) % showImgList.length]}
            onCloseRequest={() => { setIsOpen(false); setShowNav(true) }}
            onMovePrevRequest={movePrevRequest}
            onMoveNextRequest={moveNextRequest}
          />
        )}
      </Slider>

    </div>
  );
};

export default ImageGallery;
