import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHouse } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        preference: '',
        message: '',
        ip: '',
        pageUrl: window.location.href
    });

    let publicUrl = process.env.PUBLIC_URL + '/';
    const googleMapApiKey = 'AIzaSyCW7hPnNqKXjZZOZ2mpPoR7xlTKpL4LDIY'

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setFormData(formData => ({ ...formData, ip: response.data.ip }));
            } catch (error) {
                console.error('Failed to fetch IP address:', error);
            }
        };
        fetchIP();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contact_user', formData);
            console.log('Submission successful:', response.data);
            alert('Submission successful!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting form');
        }
    };

    return (
        <div>
            <Navbar />
            <PageHeader headertitle="Contact" />
            <div className="contact-area pd-top-100 pd-bottom-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <img
                                className="w-100"
                                src={publicUrl + "assets/img/companyphoto.png"}
                                alt="contact us img"
                                style={{ border: 0, height: '450px' }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
                                <h4>Contact Now</h4>
                                <div className="rld-single-input">
                                    <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} />
                                </div>
                                <div className="rld-single-input">
                                    <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} />
                                </div>
                                <div className="rld-single-input">
                                    <input type="text" name="email" placeholder="Email" onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="interest" style={{
                                        display: 'block',
                                        marginBottom: '5px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        color: '#333'
                                    }}>
                                        Select your preference:
                                    </label>
                                    <select name="preference" id="interest" onChange={handleChange} style={{
                                        width: '100%',
                                        height: '50px',
                                        backgroundColor: '#f2f2f2',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        padding: '0 10px',
                                        fontSize: '16px',
                                        color: '#333',
                                        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                                        WebkitAppearance: 'none',
                                        MozAppearance: 'none',
                                        appearance: 'none'
                                    }}>
                                        <option value="">--Select Your Preference--</option>
                                        <option value="buy">Buy</option>
                                        <option value="sell">Sell</option>
                                        <option value="rent">Rent</option>
                                        <option value="lease">Lease</option>
                                    </select>
                                </div>
                                <div className="rld-single-input">
                                    <textarea rows={10} name="message" placeholder="Message" onChange={handleChange} defaultValue={""} />
                                </div>
                                <div className="btn-wrap text-center">
                                    <button className="btn btn-yellow" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=${encodeURIComponent('Level 1, 251 Hutt St, Adelaide SA 5000')}`}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
                    <div className="row pd-top-92">
                        <div className="col-xl-4 col-sm-8">
                            <div className="single-contact-info">
                                <p><i className="fa fa-phone" />Call Us:</p>
                                <h5>+61 415 365 888</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-8">
                            <div className="single-contact-info">
                                <p><FontAwesomeIcon icon={faEnvelope} style={{ color: '#edc987' }} /> Email:</p>
                                <h5>admin@helenrealtysa.com</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-8">
                            <div className="single-contact-info">
                            <p><FontAwesomeIcon icon={faHouse} style={{ color: '#edc987' }} /> Address:</p>
                                <h5>Level 1, 251 Hutt St, Adelaide SA 5000</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;