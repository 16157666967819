import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class claim extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = sectiondata.process


        return <div className="process-area pd-top-100 pd-bottom-90">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-6 offset-lg-1 order-lg-12 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            <div className="shape-image-list right-top">
                                <img className="shadow-img" src={publicUrl + data.image} alt={imagealt} />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-12 align-self-center order-lg-1">
                        <div className="section-title mb-0">
                            {/* <h2 className="title inner-title">{data.title3} <br />{data.title2}</h2> */}
                            <p>The information provided in this article is considered to be reliable and accurate to the best of our knowledge. However, we do not guarantee its accuracy and accept no responsibility for any errors, omissions, or any outcomes resulting from using this information. This disclaimer applies to any financial losses or other damages that may result from relying on this information, including, but not limited to, land prices, floor area, and size. Furthermore, users of this information agree not to hold the author or the publishing platform responsible for any direct or indirect damages, losses, or legal actions resulting from the use or misuse of the provided information. For professional advice, please consult a qualified expert. If you have any concerns, you should seek independent advice immediately.</p>
                            {/* <a className="btn btn-yellow" href={data.url}>{data.btn_text}</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    }
}

export default claim